import { isBrowser, rejectSupersededInvocation } from '@noths/polaris-client-utils';

import type { BrowseApiPath, FetchBrowseDataArgs } from './fetchBrowseData';
import { fetchBrowseData as unguardedFetchBrowseData } from './fetchBrowseData';

export const guardedFetchBrowseData = rejectSupersededInvocation(unguardedFetchBrowseData);

const fetchBrowseData = (path: BrowseApiPath, args: FetchBrowseDataArgs) => {
  return isBrowser() ? guardedFetchBrowseData(path, args) : unguardedFetchBrowseData(path, args);
};

type GetSearchListingsDataArgs = Omit<
  FetchBrowseDataArgs,
  'categoryId' | 'previewing' | 'partnerShortcode'
>;

export const getSearchListingsData = (args: GetSearchListingsDataArgs) => {
  return fetchBrowseData('listings/v1/search', args);
};

type GetCategoryListingsDataArgs = Omit<FetchBrowseDataArgs, 'partnerShortcode' | 'searchTerm'>;

export const getCategoryListingsData = (args: GetCategoryListingsDataArgs) => {
  return fetchBrowseData('listings/v1/category', args);
};

type GetPartnerListingsDataArgs = Omit<
  FetchBrowseDataArgs,
  'categoryId' | 'searchTerm' | 'servingConfig'
>;

export const getPartnerListingsData = (args: GetPartnerListingsDataArgs) => {
  return fetchBrowseData('listings/v1/partner', args);
};
