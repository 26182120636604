import type { ReduxContentState } from 'src/redux/content/types';

export const defaults: Readonly<ReduxContentState> = {
  canonicalTag: '',
  description: '',
  heading: '',
  indexable: false,
  metaTagDescription: '',
  pageTitleOverride: null,
};

export const reduxContentBuilder = (content?: Partial<ReduxContentState>) => ({
  ...defaults,
  ...content,
});
