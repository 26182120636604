import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ReduxProductsListQueryState } from './types';

export const initialState: ReduxProductsListQueryState = {
  categoryIDs: [],
  partnerShortcode: null,
  searchTerm: null,
};

export const { actions, reducer } = createSlice({
  name: 'productsListQuery',
  initialState,
  reducers: {
    setProductsListQuery: (
      state,
      { payload }: PayloadAction<Partial<ReduxProductsListQueryState>>,
    ) => {
      return { ...state, ...payload };
    },
  },
});
