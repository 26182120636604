import type { PageEvent } from '@noths/polaris-client-next-components';
import { minBreakpoints } from '@noths/polaris-client-ribbons-base';
import type { DocumentProps } from 'next/document';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import type { ProviderMetadata } from 'src/redux/products/types';
import type {
  CategoryBaseType,
  TypeOfCategory,
} from 'src/services/browse-data-api/types/BrowseDataAPICategory';
import type { ReturnedProductObject } from 'src/types/googleAnalytics';
import type { FilterNavType } from 'src/types/navigation';
import { PageType } from 'src/types/navigation';
import { logger } from 'src/utils/serverLogger';
import { transformProductsToGAReturnedProductsArray } from 'src/utils/transformProductsToGAReturnedProductsArray';

export type PageEventCategoryType = 'Category' | 'Partner' | 'Search';
export type PageEventTypeOfCategory =
  | TypeOfCategory
  | 'Auto-populated - search based'
  | 'Auto-populated - category based';

interface BasePageEvent {
  app_version: string;
  attribution_token?: string;
  /**
   * @Category Category name
   * @Partner Partner name
   * @Search Search term
   */
  category: PageEventCategoryType;
  crs_categories?: string;
  crs_filter?: string;
  environment: typeof process.env.NODE_ENV;
  exact_listing_items?: number;
  expanded_listing_items?: number;
  filter_list: string;
  filter_nav_type: FilterNavType | '';
  /**
   * @Category Category ID
   * @Partner Partner ID
   * @Search '15000'
   */
  list_id: string | null;
  list_name: string;
  loop54_query_type: string;
  react: 'true';
  release_version: string;
  returned_products?: ReturnedProductObject[];
  total_listing_items: number;
  type_of_category?: PageEventTypeOfCategory;
}

export interface PageEventObject extends PageEvent {
  page: BasePageEvent;
}

export type PageProps = DocumentProps & { updatedReduxState: ReduxApplicationState };

export const UNDEFINED_PAGE_TYPE_ERROR = 'Page tracking event - Undefined page type';
export const UNKNOWN_PAGE_TYPE_ERROR = 'Page tracking event - Unknown page type';

export const filterNavFilterScript = `
  if (pageObject.page) {
    let filterNavType = 'sticky filter';
    if (window && window.innerWidth >= ${minBreakpoints.M}) {
      filterNavType = 'collapsed sidebar';
    }
    if (window && window.innerWidth >= ${minBreakpoints.L}) {
      filterNavType = 'fixed sidebar';
    }
    pageObject.page.filter_nav_type = filterNavType;
  }
`;

const getAsStringValueOrThrow = (value: number | string | undefined | null) => {
  if (value) {
    return String(value);
  }

  throw new Error(UNKNOWN_PAGE_TYPE_ERROR);
};

const getListId = (
  pageType: PageType,
  products: ReduxApplicationState['products'],
  productsListQuery: ReduxApplicationState['productsListQuery'],
) => {
  switch (pageType) {
    case PageType.Category:
      return getAsStringValueOrThrow(productsListQuery.categoryIDs[0]);
    case PageType.Search:
      return '15000';
    case PageType.Partner:
      return getAsStringValueOrThrow(products.partner?.id);
    default:
      throw new Error(UNKNOWN_PAGE_TYPE_ERROR);
  }
};

const getListName = (pageType: PageType, products: ReduxApplicationState['products']) => {
  switch (pageType) {
    case PageType.Category:
      return getAsStringValueOrThrow(products.category?.name);
    case PageType.Search:
      return getAsStringValueOrThrow(products.searchTerm);
    case PageType.Partner:
      return getAsStringValueOrThrow(products.partner?.name);
    default:
      throw new Error(UNKNOWN_PAGE_TYPE_ERROR);
  }
};

const getPageEventCategory = (pageType: PageType): PageEventCategoryType => {
  switch (pageType) {
    case PageType.Category:
      return 'Category';
    case PageType.Partner:
      return 'Partner';
    case PageType.Search:
      return 'Search';
    default:
      return pageType;
  }
};

const convertPathnameToCategoryBreadcrumb = (pathname: string) => {
  return pathname.replace(/^\/|\/$/g, '').replace(/\//g, ' > ');
};

const getCRSCategories = (
  pageType: PageType,
  providerMetadata: ProviderMetadata | null,
  pathname: string,
) => {
  switch (pageType) {
    case PageType.Category:
      return providerMetadata && providerMetadata.pageCategories[0]
        ? providerMetadata.pageCategories[0]
        : convertPathnameToCategoryBreadcrumb(pathname);
    case PageType.Partner:
      return convertPathnameToCategoryBreadcrumb(pathname);
    default:
      return null;
  }
};

const transformToGATypeOfCategory = (
  typeOfCategory: TypeOfCategory,
  baseType: CategoryBaseType,
): PageEventTypeOfCategory => {
  if (typeOfCategory === 'Auto-populated') {
    if (baseType === 'search_based') {
      return `${typeOfCategory} - search based`;
    }
    if (baseType === 'category_based') {
      return `${typeOfCategory} - category based`;
    }
  }

  return typeOfCategory;
};

/**
 * This is to add the page object immediately into datalayer before
 * the gtm.start tag fires
 */
export const getServerSidePageEventObject = (
  version: string,
  appVersion: string,
  updatedReduxState?: ReduxApplicationState,
): PageEventObject => {
  if (
    !updatedReduxState ||
    Object.keys(updatedReduxState).length === 0 ||
    !updatedReduxState.navigation
  ) {
    return {} as PageEventObject;
  }

  try {
    const { filter, navigation, products, productsListQuery } = updatedReduxState;
    const pageType = navigation.pageType!;
    const listId = getListId(pageType, products, productsListQuery);
    const listName = getListName(pageType, products);

    const {
      category,
      currentPage,
      items,
      pinnedResultCount,
      providerAttributionToken,
      providerMetadata,
      queryType,
      totalProducts,
      zeroExactResults,
    } = products;
    const exactListingItemsCount = pinnedResultCount || 0;
    const hasExpandedSearchResults = pinnedResultCount || zeroExactResults;
    const crsCategories = getCRSCategories(pageType, providerMetadata, navigation.pathname);

    return {
      page: {
        category: getPageEventCategory(pageType),
        environment: process.env.NODE_ENV,
        release_version: version,
        app_version: appVersion,
        filter_list: filter.collections.filters.map((f) => f.title).join(','),
        // Gets set on page load when nav type is calculated
        filter_nav_type: '',
        list_id: listId,
        list_name: listName,
        loop54_query_type: queryType,
        react: 'true',
        total_listing_items: totalProducts,
        ...(hasExpandedSearchResults && {
          exact_listing_items: exactListingItemsCount,
          expanded_listing_items: totalProducts - exactListingItemsCount,
        }),
        ...(category?.typeOfCategory && {
          type_of_category: transformToGATypeOfCategory(category.typeOfCategory, category.baseType),
        }),
        ...(providerAttributionToken && { attribution_token: providerAttributionToken }),
        ...(providerMetadata && { crs_filter: providerMetadata.filter }),
        ...(crsCategories && { crs_categories: crsCategories }),
        ...(items?.[currentPage] && {
          returned_products: transformProductsToGAReturnedProductsArray(items[currentPage]),
        }),
      },
    };
  } catch (error) {
    logger.warn({
      location: 'generatePageEventObject',
      extra: { error },
    });

    return {} as PageEventObject;
  }
};
