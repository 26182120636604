import type { ReduxContentState } from 'src/redux/content/types';
import type { BrowseDataAPICategory } from 'src/services/browse-data-api/types/BrowseDataAPICategory';

export const adaptProductsCategoryToContentActionPayload = (
  category: BrowseDataAPICategory,
): ReduxContentState => {
  const {
    canonicalTag,
    description,
    heading,
    indexable,
    metaTagDescription,
    pageTitleOverride,
    subheading,
  } = category;

  return {
    canonicalTag,
    description,
    heading,
    indexable,
    metaTagDescription,
    pageTitleOverride,
    subheading,
  };
};
