import type { RequestProductsActionPayloadData } from 'src/redux/products/types';
import type { BrowseDataAPIResponseBody } from 'src/services/browse-data-api/types/BrowseDataAPIResponseBody';

export const adaptProductsDataToProductsActionPayload = (
  browseServiceProductsData: BrowseDataAPIResponseBody,
): RequestProductsActionPayloadData => {
  const {
    attributionToken,
    category,
    currencyCode,
    currentPage,
    filters,
    navigation,
    partner,
    perPage,
    pinnedResultCount,
    products,
    productsReturnedCount,
    providerMetadata,
    queryType,
    sortingOptions,
    term,
    totalPages,
    totalProducts,
    zeroExactResults,
  } = browseServiceProductsData;

  return {
    category: category
      ? {
          baseType: category.baseType,
          hierarchy: category.hierarchy,
          name: category.name,
          seoCategories: category.seoCategories,
        }
      : null,
    currencyCode,
    products,
    perPage,
    filters,
    currentPage,
    totalPages,
    totalProducts,
    productsReturnedCount,
    queryType,
    sortingOptions,
    term,
    navigation,
    partner,
    providerAttributionToken: attributionToken || null,
    zeroExactResults,
    pinnedResultCount,
    providerMetadata,
  };
};
