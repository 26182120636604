import { reducer as userConfigurationReducer } from '@noths/polaris-client-user-configuration';
import { reducer as favouritesReducer } from '@noths/polaris-client-user-favourites';
import { combineReducers } from '@reduxjs/toolkit';
import { routerReducer } from 'connected-next-router';

import { reducer as filterReducer } from 'src/components/organisms/Filter/modules/slice';
import { reducer as filterMenuReducer } from 'src/components/organisms/FilterMenu/modules/slice';
import { reducer as accessibilityReducer } from 'src/redux/accessibility/slice';
import { reducer as contentReducer } from 'src/redux/content/slice';
import { reducer as navigationReducer } from 'src/redux/navigation/slice';
import { reducer as productReducer } from 'src/redux/products/slice';
import { reducer as productsListQueryReducer } from 'src/redux/productsListQuery/slice';
import { reducer as richRelevanceReducer } from 'src/redux/richRelevance/slice';
import { reducer as userReducer } from 'src/redux/user/slice';
import { reducer as visibilityTrackingReducer } from 'src/redux/visibilityTracking/slice';

export const combinedReducer = combineReducers({
  accessibility: accessibilityReducer,
  content: contentReducer,
  filter: filterReducer,
  filterMenu: filterMenuReducer,
  navigation: navigationReducer,
  products: productReducer,
  productsListQuery: productsListQueryReducer,
  router: routerReducer,
  user: userReducer,
  userConfiguration: userConfigurationReducer,
  favourites: favouritesReducer,
  richRelevance: richRelevanceReducer,
  visibilityTracking: visibilityTrackingReducer,
});

export type ReduxApplicationState = ReturnType<typeof combinedReducer>;
