import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { defaults } from './builders';
import type { ReduxContentState } from './types';

export const initialState: ReduxContentState = defaults;

export const { actions, reducer } = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setContent: (state, { payload }: PayloadAction<ReduxContentState>) => {
      return { ...state, ...payload };
    },
  },
});
