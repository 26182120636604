/* istanbul ignore file */

import { sendGAEvent } from '@noths/polaris-client-google-analytics';
import type { EventsMap } from '@noths/polaris-client-next-redux';
import { selectIsFavourite, updateFavourite } from '@noths/polaris-client-user-favourites';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { getProductFieldObject } from 'src/utils/googleAnalytics';
import type { FavouriteEventLabel, FavouriteProductEvent } from './events';

const getEventLabel = (
  isOpeningModal: boolean,
  isExistingFavourite: boolean,
): FavouriteEventLabel => {
  if (isOpeningModal) {
    return 'folder options opened';
  }

  if (isExistingFavourite) {
    return 'removed';
  }

  return 'added';
};

type Action = ReturnType<(typeof updateFavourite)['fulfilled']>;

export const GAEventsMap: EventsMap<ReduxApplicationState> = {
  [updateFavourite.fulfilled.type]: (state, _, action) => {
    const { payload: productCode } = action as Action;
    const { favourites, navigation, products, productsListQuery } = state;
    const { currentPage, items, productsReturnedCount } = products;
    const { isModalOpen, summary } = favourites;
    const { pageType } = navigation;
    const isFavourite = selectIsFavourite(summary, productCode);
    const favouritedProduct = items[currentPage].find((prod) => prod.code === productCode)!;
    const categoryId = productsListQuery.categoryIDs?.[0];

    sendGAEvent<FavouriteProductEvent>({
      event: 'custom_event',
      event_category: 'Favourites',
      event_action: 'heart icon clicked',
      event_label: getEventLabel(isModalOpen, isFavourite),
      favourites: getProductFieldObject(
        favouritedProduct,
        items,
        productsReturnedCount,
        categoryId,
        pageType,
      ),
    });
  },
};
