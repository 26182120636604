import type { ReduxProductsCategory } from 'src/redux/products/types/reduxProductsCategory';

export const defaults: Readonly<ReduxProductsCategory> = {
  hierarchy: [],
  baseType: null,
  name: '',
  typeOfCategory: 'Open to partner (OTP)',
  seoCategories: [],
};

export const reduxCategoryBuilder = (category?: Partial<ReduxProductsCategory>) => ({
  ...defaults,
  ...category,
});
